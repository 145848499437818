<template>
  <div>
    <b-jumbotron class="fundo">
      <b-row cols="8">
        <b-col offset-lg="2" offset-md="0" offset-sm="0"> </b-col>
        <b-col sm="8" lg="6" md="7">
          <b-card>
            <b-card-header>
              <b-card-title> Inscrições </b-card-title>
              <b-card-sub-title> Congresso Arquidiocesano RCC Belém </b-card-sub-title>
            </b-card-header>
            <b-card-body style="text-align: justify">
              <b-card-text>
                O Congresso Arquidiocesano será a oportunidade de alcançar várias pessoas da nossa Região Metropolitana, buscando momentos de oração, formação, louvor, animação e pregação com todos reunidos, adultos, jovens e crianças.
                Para celebrar esse reencontro da família carismática teremos um fim de semana com atividades especificas de acordo com etapa de vida de cada um dos participantes para desenvolver a espiritualidade e a fraternidade.
                </b-card-text>
                <!-- <b-card-text class="text-center"><h5>Palestrantes Confirmados</h5></b-card-text>

              <b-list-group flush>
                <b-list-group-item>
                     <b-img src="/palestrantes/gustavo.jpg" width="70px" height="70px" rounded="circle" alt="Gustavo"></b-img>
                     <span class="mr-auto"> Prof. Msc. Gustavo Francesco de Morais Dias </span>
                  </b-list-group-item>
                  <b-list-group-item style="text-align: right"> 
                     <span class="mr-auto"> Téc. Meio Ambiente Assucena Lebre </span>
                     <b-img src="/palestrantes/assucena.jpeg" width="70px" height="70px" rounded="circle" alt="Gustavo"></b-img>
                  </b-list-group-item>
                  <b-list-group-item>
                     <b-img src="/palestrantes/aline.jpg" width="70px" height="70px" rounded="circle" alt="Gustavo"></b-img>
                     <span class="mr-auto"> Prof.Dra. Aline Maria Meiguins De Lima </span>
                  </b-list-group-item>
                  <b-list-group-item style="text-align: right"> 
                     <span class="mr-auto"> Prof. Dr. Carlos Alexandre Leão Bordalo </span>
                     <b-img src="/palestrantes/bordalo.jpg" width="70px" height="70px" rounded="circle" alt="Gustavo"></b-img>
                  </b-list-group-item>
              </b-list-group> -->
              <b-card-text
                >Para participar desse momento enriquecedor de conhecimento
                basta fazer a sua inscrição:</b-card-text
              >
              <div class="text-center">
                <p>
                  <!-- <b-button variant="success" style="padding-right: 20px" href="#/inscricao">Realizar Inscrição</b-button> -->
                   <b-button variant="primary" href="#/acessar-acompanhamento">Acompanhar inscrição</b-button>
                </p>
                <p>
                  <b-button target="_blank" disabled variant="primary" href="/arquivos/cronograma.pdf"
                    >Veja a programação</b-button
                  >
                </p>
              </div>
            </b-card-body>
            <b-card-footer
              >Renovação Carismática Católica da Arquidiocese de Belém</b-card-footer
            >
          </b-card>
        </b-col>
        <b-col offset-lg="2" offset-md="0" offset-sm="0"> </b-col>
      </b-row>
    </b-jumbotron>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.fundo {
  background-image: url(https://d335luupugsy2.cloudfront.net/cms/files/20536/1637239347/$4aku62hrdpb), linear-gradient(to bottom, rgba(135,27,31,1) 0%, rgba(135,27,31,1) 100%);
  /* background-image: url("/fundo.png"); */
  background-size: cover;
  height: 100%;
}
</style>