<template>
    <div>
      <b-jumbotron class="fundo">
        <b-row cols="10">
          <b-col offset-lg="1" offset-md="0" offset-sm="0"> </b-col>
          <b-col sm="10" lg="4" md="9">
            <b-card>
              <b-card-header>
                <b-card-title>
        Inscrições
     </b-card-title>
      <b-card-sub-title>
          Congresso Arquidiocesano RCC Belém
      </b-card-sub-title>
              </b-card-header>
              <b-card-body v-show="loading">
       <div>
        <b-spinner variant="primary" label="Verificando Informações..."></b-spinner>
      </div>
     </b-card-body>
              <b-card-body>
                <b-card-text>
                   Nome: {{retorno.nome}}
                </b-card-text>
                <b-card-text>
                   CPF: {{retorno.cpf}}
                </b-card-text>
                <b-card-text>
                   Tipo: {{retorno.tipo}}
                </b-card-text>
                <b-card-text>
                   Formato: {{retorno.formato}}
                </b-card-text>
                <!-- <b-card-text>
                   Data Inscrição: {{retorno.dataInscricao | 'dd/MM/yyyy'}}
                </b-card-text> -->
                <!-- <b-card-text>
                   Data Pagamento: {{retorno.dataPagamento}}
                </b-card-text> -->
                <b-card-text>
                   Status da Inscricao: {{ retorno.status }}
                </b-card-text>
                <b-card-text v-show="retorno.status != 'PAGA'">
                    Após efetuar o pagamento no link abaixo, enviar o comprovante e cpf para o email: <b>tesourariarccbelem@gmail.com</b>
                </b-card-text>
                <b-card-text v-show="retorno.status != 'PAGA'">
                    <!-- INICIO DO BOTAO PAGSEGURO -->
                    <a :href="retorno.link" target="_blank" title="Pagar com PagSeguro">
                        <img src="//assets.pagseguro.com.br/ps-integration-assets/botoes/pagamentos/205x30-pagar.gif" alt="Pague com PagSeguro - é rápido, grátis e seguro!" /></a>
                    <!-- FIM DO BOTAO PAGSEGURO -->
                </b-card-text>
                <div>
        <b-row>
          <b-col>
            <b-button pill @click="sair()" class="tamanhoBotao" variant="danger">
               <!-- <b-icon icon="exit"/> -->
            Sair</b-button>
          </b-col>
        </b-row>
      </div>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col offset-lg="1" offset-md="0" offset-sm="0"> </b-col>
        </b-row>
      </b-jumbotron>
    </div>
  </template>
  <script>
    export default {
      name: "Acompanhamento",
      data() {
        return {
          retorno: {
            nome: localStorage.getItem('nome'),
            cpf:  localStorage.getItem('cpf'),
            dataInscricao: localStorage.getItem('dataInscricao'),
            dataPagamento: localStorage.getItem('dataPagamento'),
            status: localStorage.getItem('status'),
            link: localStorage.getItem('link'),
            formato: localStorage.getItem('formato'),
            tipo: localStorage.getItem('tipo')
          },
          loading: false,
        };
      },
      methods: {
        sair(){
            localStorage.clear();
            this.$router.push("/");
        },
        limpar(){
          this.solicitacao.nome = null;
          this.solicitacao.cpf = null;
          this.solicitacao.instituicao = null;
          this.solicitacao.curso = null;
          this.solicitacao.email = null;
        },
        verificaLocalStorage(){

            if ( localStorage.getItem('nome')){
                this.$router.push("/");
            }
        }
      },
      async mounted() {
        // this.verificaLocalStorage();
      },
    };
    </script>
  
  
  
  <style scoped>
  .fundo {
    /* background-image: url("/fundo.png"); */
    background-image: url(https://d335luupugsy2.cloudfront.net/cms/files/20536/1637239347/$4aku62hrdpb), linear-gradient(to bottom, rgba(135,27,31,1) 0%, rgba(135,27,31,1) 100%);
    background-size: cover;
    height: 100%;
  }
  .tamanhoBotao {
    width: 110px;
  }
  </style>