// Desenvolvimento
// const baseURL = 'http://localhost/'
// Produção
const baseURL = 'https://inscricao-congresso-rccbelem.herokuapp.com/'

var headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('token'),
}

function preencheHeader(){
    return headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
    }
}

export { baseURL, headers,  preencheHeader}