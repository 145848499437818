import Home from './../components/Home.vue'
// import Inscricao from './../components/Inscricao.vue'
import Acompanhamento from './../components/Acompanhamento.vue'
import AcessoAcompanhamento from './../components/AcessoAcompanhamento.vue'
import {estaAcessandoNome} from './acesso'

const routes = [
    {
        path: '/',
        component: h => h(Home)

    },
    {
        path: '/inscricao',
        component: h => h(Home)

    },
    {
        path: '/acessar-acompanhamento',
        component: h => h(AcessoAcompanhamento)

    },
    {
        path: '/acompanhamento',
        component: h => h(Acompanhamento),
            beforeEnter(_, __, next) { // Impede usuários não assinados
                if (estaAcessandoNome()) { // de acessar a página Home.
                    next();
                    return;
                }
                next('/')
            }

    },
    // {
    //     path: '/acesso-administrativo-lirio',
    //     component: h => h(Acesso)

    // },
    // {
    //     path: '/acesso',
    //     component: h =>h(AcessoCertificado)

    // },
    // {
    //     path: '/confirmacao-presenca-palestra',
    //     component: h => h(ConfirmacaoPalestra)

    // },
    // {
    //     path: '/autenticidade',
    //     component: h => h(Autenticidade)

    // },
    // {
    //     path: '/certificado',
    //     component: h => h(Certificado),
    //     beforeEnter(_, __, next) { // Impede usuários não assinados
    //         if (estaAcessando()) { // de acessar a página Home.
    //             next();
    //             return;
    //         }
    //         next('/')
    //     }
    //   },
    // {
    //       path: '/administrativo',
    //       component: h => h(Dashboard),
    //       beforeEnter(_, __, next) { // Impede usuários não assinados
    //           if (estaAcessando()) { // de acessar a página Home.
    //               next();
    //               return;
    //           }
    //           next('/')
    //       }
    //     },
    // {
    //     path: '/*',
    //     component: h => h(Home)

    // }
  ]

  export { routes };