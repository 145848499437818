<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  background-image: url(https://d335luupugsy2.cloudfront.net/cms/files/20536/1637239347/$4aku62hrdpb), linear-gradient(to bottom, rgba(135,27,31,1) 0%, rgba(135,27,31,1) 100%);
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
