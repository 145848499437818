<template>
    <div>
    <b-jumbotron class="fundo">
          <b-row cols="8">
            <b-col offset-lg="2" offset-md="0" offset-sm="0"> </b-col>
            <b-col sm="8" lg="6" md="7">
      <b-card>
       <b-card-header>
         <b-card-title>
            Inscrições
         </b-card-title>
          <b-card-sub-title>
              Congresso Arquidiocesano RCC Belém
          </b-card-sub-title>
       </b-card-header>
       <b-card-body v-show="loading">
         <div>
          <b-spinner variant="primary" label="Validando acesso..."></b-spinner>
        </div>
       </b-card-body>
       <b-card-body> 
        <b-form @submit="cadastrar" @reset="limpar" href="#" v-show="!loading">
          <!-- <b-form> -->
          <b-form-group id="input-group-2" label="CPF:" label-for="input-2">
            <b-form-input
              id="input-2"
              v-model="solicitacao.cpf"
              placeholder="CPF"
              required
              v-mask="'###.###.###-##'"
            ></b-form-input>
          </b-form-group>
          <!-- <b-form-group id="input-group-5" label="E-mail:" label-for="input-5">
            <b-form-input
              id="input-5"
              v-model="solicitacao.email"
              placeholder="Email"
              type="email"
              required
            ></b-form-input>
          </b-form-group> -->
          <div>
            <b-row>
              <b-col>
                <b-button pill type="reset" class="tamanhoBotao" variant="danger">
                  <b-icon icon="x-circle" aria-hidden="true"></b-icon>
                  Limpar</b-button>
              </b-col>
              <b-col>
                <b-button pill type="submit" class="tamanhoBotao" variant="primary">
                   <b-icon icon="pencil"/>
                 Acessar acompanhamento</b-button>
              </b-col>
            </b-row>
          </div>
        </b-form>
       </b-card-body>
      </b-card></b-col>
            <b-col offset-lg="2" offset-md="0" offset-sm="0"> </b-col>
          </b-row>
        </b-jumbotron>
    
    </div>
    </template>
    
    <script>
    import axios from "axios";
    import {baseURL } from './../config/configuracao'
    export default {
      name: "Inscricao",
      data() {
        return {
          status: null,
          solicitacao: {
            cpf: null,
            email: null,
          },
          loading: false,
          visualizacao: false,
        };
      },
      methods: {
        limpar(){
          this.solicitacao.cpf = null;
          this.solicitacao.email = null;
        },
        async cadastrar(event) {
          event.preventDefault()
          this.loading = true;
          await axios({
            url: baseURL+"inscricao/acompanhamento",
            method: "POST",
            data: this.solicitacao,
          }).then( (response) => {
              console.log('estou aqui também')
              localStorage.setItem("nome", response.data.nome);
              localStorage.setItem("tipo", response.data.tipo);
              localStorage.setItem("formato", response.data.formato);
              localStorage.setItem("cpf", response.data.cpf);
              localStorage.setItem("status", response.data.status);
              localStorage.setItem("link", response.data.pagamento);
              localStorage.setItem("dataInscricao", response.data.dataIncricao);
              this.$bvToast.toast('Inscrição da pessoa '+ response.data.nome+' recebida com sucesso!', {
                title: "Sucesso",
                variant: "success",
                solid: true
              });
              this.$router.push("/acompanhamento");
              this.limpar();
          }).catch((error) => {
              if (error.response.data.mensagem){
                this.$bvToast.toast(error.response.data.mensagem, {
                title: "Error",
                variant: "danger",
                solid: true
              })
              } else {
                this.$bvToast.toast("Inscrição não encontrada. Realize sua inscrição para o Congresso Arquidiocesano 2022!", {
                  title: "Error",
                  variant: "danger",
                  solid: true
                })
              }
          })
          this.loading = false;
        } 
      }
    };
    </script>
    
    <style scoped>
    .fundo {
      /* background-image: url("/fundo.png"); */
      background-image: url(https://d335luupugsy2.cloudfront.net/cms/files/20536/1637239347/$4aku62hrdpb), linear-gradient(to bottom, rgba(135,27,31,1) 0%, rgba(135,27,31,1) 100%);
      background-size: cover;
      height: 100%;
    }
    </style>